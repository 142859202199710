import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import FaqsWithButtons from '../../components/UI/FaqsWithButtons'
import QuestionsAccordion from '../../components/UI/QuestionsAccordion'

const CreditScoreFAQ = () => {

  const queriedFAQsContainer = useStaticQuery(graphql`
    query CreditScoreFAQ {
      contentfulComponentFaQsContainer(page: {eq: "Credit Score"}) {
        faq {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <ContentContainer>
      <FaqsWithButtons page={queriedFAQsContainer.contentfulComponentFaQsContainer} faqsLength={5}>
				<QuestionsAccordion />
			</FaqsWithButtons>
    </ContentContainer>
  )
}



export default CreditScoreFAQ
