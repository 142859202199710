import React, { useState } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image"

import ContentContainer from '../UI/ContentContainer'
import { URL } from '../../support'
import CreditScoreCarousel from './CreditScoreCarousel'
// import MmeModal from '../UI/Modal'
import AppStoreBig from '../../assets/images/svg/app-store-big.svg'
import AppStore from '../../assets/images/svg/app-store-logo.svg'
import GooglePlayBig from '../../assets/images/svg/google-play-big.svg'
import GooglePlay from '../../assets/images/svg/google-play-logo.svg'

const MmeModal = loadable(() => import('../UI/Modal'))
const CreditScoreHero = ({ ai = false}) => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Apps = () => {
    if(typeof window !== 'undefined' && (navigator.userAgent.indexOf('Android') !== -1 || navigator.userAgent.indexOf('iPhone') !== -1)) {
      return (
        navigator.userAgent.indexOf('iPhone') !== -1 ?
          <AppLink href={URL.MME_APP_STORE}>
            <AppStoreBig />
          </AppLink>
        :
          <AppLink href={URL.MME_GOOGLE_PLAY}>
            <GooglePlayBig />
          </AppLink>
      )
    } else {
      return (
        <>
          <AppLinks onClick={handleClickOpen}>
            <ImgWrapper>
              <AppStore />
            </ImgWrapper>
            <ImgWrapper>
              <GooglePlay />
            </ImgWrapper>
          </AppLinks>
          <MmeModal open={open} handleClose={handleClose} />
        </>
      )
    }
  }
  const imageWidth = 500
  return (
    <ContentContainer>
      <CreditScoreHeroWrapper className="hp-hero-grid w-layout-grid">
        <div className="col">
          <h1>
            Get More From Your{' '}
            <span className="purpleHeader">Credit Score</span>
          </h1>
          <p className="subHeading">
            Banks and lenders know your credit score. <span><strong>Do you?</strong></span>
          </p>
          <Content>
            We are now part of MONEYME. Check your credit score by downloading the MONEYME app now!
          </Content>
          <AppLinksWrapper>
            <Apps />
          </AppLinksWrapper>
        </div>

        {!ai ?
          <div className="hero-img">
            <CreditScoreCarousel />
          </div>
        :
          <MainImg>
            <StaticImage src='../../assets/images/ai-credit-score.png' alt="manWithPhone" placeholder='none' loading='eager' width={imageWidth} height={imageWidth} imgStyle={{borderRadius: "50%"}} formats={["png"]}/>
					</MainImg>
        }
      </CreditScoreHeroWrapper>
    </ContentContainer>
  )
}

const CreditScoreHeroWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .col {
    max-width: 608px;
  }
  .purpleHeader {
    color: #474ed3;
  }
  .hero-img {
    flex-shrink: 0;
    margin-right: 50px;
    width: 40vw;
    max-width: 427px;
  }
  button {
    margin: 40px 20px 40px 0px;
  }
  .sub-button {
    font-size: 18px;
  }
  .subHeading {
    font-size: 20px;
    .bold {
      font-family: 
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    .hero-img {
      width: 60vw;
      margin-top: 20px;
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
    h1 {
      font-size: 32px;
    }
    .subHeading {
      font-size: 16px;
      width: 60vw;
      margin: 15px auto;
    }
    .sub-button {
      font-size: 16px;
    }
  }
`
const Content = styled.p`
  margin: 25px 0 15px 0;
  font-size: 16px;
  color: #000000;
  max-width: 437px;
  @media screen and (max-width: 768px) {
    max-width: 70vw;
    margin: 40px auto 20px;
  }
`
const AppLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

`
const AppLink = styled.a`

`
const ImgWrapper = styled.div`
  margin-right: 20px;
`
const AppLinksWrapper = styled.div`
  margin: 30px 0 60px 0;
  @media screen and (max-width: 768px) {
    margin: 20px 0 30px 0;
  }
`
const MainImg = styled.div`
	justify-self: center;
	@media screen and (max-width: 768px) {
		margin: 50px 0 30px 0;
		width: 60vw;
	};
	@media screen and (max-width: 479px) {
		width: 80vw;
	}
`
export default CreditScoreHero
