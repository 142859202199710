import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import Benefit from '../UI/Benefit'

const CreditScoreBenefits = () => {
  const queriedBenefitsContainer = useStaticQuery(graphql`
    query CreditScorePageBenefitsQuery {
      contentfulComponentBenefitsContainer(
        page: { eq: "Credit Score page" }
      ) {
        page
        benefits {
          header {
            childMarkdownRemark {
              html
            }
          }
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return (
    <ContentContainer padding="0 0 120px">
      <Benefit
        page={queriedBenefitsContainer.contentfulComponentBenefitsContainer}
      />
    </ContentContainer>
  )
}

export default CreditScoreBenefits
